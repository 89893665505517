<template>
	<div class="modal-wrapper">
		<div class="modal-overlay"
				 @click="closeModal"></div>

		<section class="dashboard-options-modal modal card shadow"
						 role="dialog"
						 aria-labelledby="DashboardOptionsModalTitle">

			<header class="flex-row flex-align-spaced flex-center-v flex-nowrap">
				<h2 id="DashboardOptionsModalTitle">Options de chargement des questions</h2>
				<button class="button--icon"
								@click="closeModal">

					<inline-svg title="Fermer"
											class="icon"
											fill="none"
											role="img"
											width="14"
											height="14"
											:src="require('@/assets/svg/icons/close.svg')"></inline-svg>
				</button>
			</header>

			<div class="content">
				<form id="formQuestionsLoadOptions"
							@submit.prevent="handleSubmit"></form>

				<div class="input-group flex-gap-s">
					<label for="questionsLoadRange">
						Nombre de questions
					</label>
					<select ref="questionsLoadRange"
									id="questionsLoadRange"
									name="questions-load-range"
									class="vgt-select"
									form="formQuestionsLoadOptions"
									v-model="localOptions.limit"
									@change="handleSelect">
						<option value="last-months">3 derniers mois</option>
						<option value="last-year">Dernière année</option>
						<option value="all">Toutes les questions</option>
					</select>
				</div>

				<div class="input-group--inline">
					<input type="checkbox"
								 v-model="localOptions.includeComplete"
								 ref="includeComplete"
								 id="includeComplete"
								 name="includeComplete"
								 form="formQuestionsLoadOptions">
					<label for="includeComplete">Charger les questions terminées</label>
				</div>

			</div>


			<footer class="flex-row flex-center-h">
				<button type="submit"
								form="formQuestionsLoadOptions"
								class="button button--primary">Enregistrer</button>
			</footer>

		</section>
	</div>
</template>


<script>
export default {
	name: "DashboardOptionsModal",

	props: ['isOpen'],

	data: function () {
		return {
			// local model
			localOptions: {}
		};
	},

	computed: {
		// current options
		questionsLoadOptions() {
			return this.$store.state.questionsLoadOptions
		}
	},

	async mounted() {
		// init local model
		this.localOptions = { ...this.questionsLoadOptions };
	},

	methods: {

		// handle select
		handleSelect(e) {
			if (this.localOptions.limit === 'all') {
				this.localOptions.includeComplete = true;
				this.$refs.includeComplete.setAttribute('disabled', true);
			}
			else {
				this.$refs.includeComplete.removeAttribute('disabled');
			}
		},

		// update options
		async handleSubmit(e) {
			const payload = { ...this.localOptions };
			payload.includeComplete = payload?.includeComplete ? true : false;
			this.$store.dispatch("UPDATE_QUESTIONS_LOAD_OPTIONS", payload);
			this.$emit("questionsLoadOptions");
			this.closeModal();
		},

		// close modal
		closeModal: function () {
			this.$emit("modalClose");
		},

	},


	watch: {
		// reload options from store when the modal opens
		isOpen: {
			immediate: true,
			handler: function (after, before) {
				if (after === true) {
					this.localOptions = { ...this.questionsLoadOptions };
				}
			},
		},

	}

};
</script>


<style lang="scss" scoped>
@import "~@/scss/02-tools/tools.mixins";
@import "~@/scss/06-components/components.modal";

section>*+* {
	margin-top: 1rem;
}

.modal {
	width: 40rem;
	max-width: 100%;
	min-height: 20rem;

	display: flex;
	flex-flow: column;

	.content {
		flex-grow: 1;
	}
}

.button--primary {
	flex-basis: 8rem;
	justify-content: center;
}

[disabled]+label {
	opacity: .7;
}
</style>