<template>
	<article :class="user_is_coordinator ? 'is-coordinator' : ''"
					 class="view-dashboard"
					 @click="clearToasts">
		<div class="site-wrapper rythm-v-l">
			<header class="flex-col flex-gap-s">

				<h1>Mes questions</h1>

				<div class="questions-options flex-row flex-gap-s flex-center-v"
						 @click="openDashboardOptions">

					<!-- options -->
					<button type="button"
									class="button--icon button--circle"
									aria-label="Options de chargement des questions"
									title="Options de chargement des questions">
						<inline-svg class="icon"
												fill="none"
												:src="require('@/assets/svg/icons/icon-settings.svg')"></inline-svg>
					</button>

					<span class="tag--info"
								v-if="questionsLoadOptions.limit === 'last-months'">
						<svg xmlns="http://www.w3.org/2000/svg"
								 width="16"
								 height="16"
								 viewBox="0 0 24 24"
								 fill="none"
								 stroke="currentColor"
								 stroke-width="2"
								 stroke-linecap="round"
								 stroke-linejoin="round"
								 class="icon"><path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"/><circle cx="12" cy="12" r="3"/></svg> 3 derniers mois</span>
					<span class="tag--info"
								v-if="questionsLoadOptions.limit === 'last-year'">
						<svg xmlns="http://www.w3.org/2000/svg"
								 width="16"
								 height="16"
								 viewBox="0 0 24 24"
								 fill="none"
								 stroke="currentColor"
								 stroke-width="2"
								 stroke-linecap="round"
								 stroke-linejoin="round"
								 class="icon"><path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"/><circle cx="12" cy="12" r="3"/></svg> dernière année</span>

					<span class="tag--info"
								v-if="questionsLoadOptions.limit === 'all'">
						<svg xmlns="http://www.w3.org/2000/svg"
								 width="16"
								 height="16"
								 viewBox="0 0 24 24"
								 fill="none"
								 stroke="currentColor"
								 stroke-width="2"
								 stroke-linecap="round"
								 stroke-linejoin="round"
								 class="icon"><path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"/><circle cx="12" cy="12" r="3"/></svg> toutes les questions</span>

					<span class="tag--info"
								v-if="questionsLoadOptions.limit !== 'all'">

						<svg v-if="!questionsLoadOptions.includeComplete"
								 xmlns="http://www.w3.org/2000/svg"
								 width="16"
								 height="16"
								 viewBox="0 0 24 24"
								 fill="none"
								 stroke="currentColor"
								 stroke-width="2"
								 stroke-linecap="round"
								 stroke-linejoin="round"
								 class="icon"><path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"/><path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"/><path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"/><line x1="2" x2="22" y1="2" y2="22"/></svg>

						<svg v-if="questionsLoadOptions.includeComplete"
								 xmlns="http://www.w3.org/2000/svg"
								 width="16"
								 height="16"
								 viewBox="0 0 24 24"
								 fill="none"
								 stroke="currentColor"
								 stroke-width="2"
								 stroke-linecap="round"
								 stroke-linejoin="round"
								 class="icon"><path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"/><circle cx="12" cy="12" r="3"/></svg> terminées</span>
				</div>

			</header>

			<section class="section--questions flex-row rythm-v">
				<header class="offscreen">
					<h2>Liste des questions</h2>
				</header>

				<div class="toolbar card flex-item--100"
						 data-toolbar="questions">

					<div class="flex-row flex-nowrap flex-item--100 flex-center-v flex-gap-s">

						<!-- options -->
						<!-- <button type="button"
										class="button--icon"
										aria-label="Options de chargement des questions"
										title="Options de chargement des questions"
										@click="openDashboardOptions">
							<inline-svg class="icon"
													fill="none"
													:src="require('@/assets/svg/icons/icon-settings.svg')"></inline-svg>
						</button> -->

						<!-- search -->
						<div class="search flex-row flex-item--grow flex-gap">
							<label for="search"
										 class="offscreen">Recherche</label>
							<div class="flex-item--auto flex-row flex-nowrap flex-center-v flex-gap-s">
								<input type="search"
											 name="search"
											 id="search"
											 ref="search"
											 v-model="search"
											 placeholder="Recherche par mots clés"
											 @keyup="handleAutoSearch" />
								<button type="submit"
												form="searchForm"
												class="button--icon">
									<svg role="img"
											 aria-hidden="true"
											 class="icon icon--search"
											 viewBox="0 0 15 15"
											 fill="none"
											 xmlns="http://www.w3.org/2000/svg"
											 width="15"
											 height="15"><path style="fill:none !important" d="M14.5 14.5l-4-4m-4 2a6 6 0 110-12 6 6 0 010 12z" stroke="currentColor"></path></svg>
									<span class="caption">Rechercher</span>
								</button>
							</div>
							<form name="searchForm"
										id="searchForm"
										@submit.prevent="handleQuestionSearch"></form>
						</div>

					</div>


					<router-link to="/edit-question-themes/0"
											 :class="rows.length ? 'button--primary' : 'button--primary heartbeat'"
											 v-if="!user_is_coordinator && !user_is_expert && !user_is_expert_ext">
						<svg xmlns="http://www.w3.org/2000/svg"
								 width="15"
								 height="16"
								 fill="none"
								 class="icon"
								 role="img"><path fill="currentColor" d="M8.19579 1C8.19579 0.447715 7.78808 0 7.28515 0C6.78222 0 6.37451 0.447715 6.37451 1V7H0.910638C0.407707 7 0 7.44772 0 8C0 8.55229 0.407707 9 0.910638 9H6.37451V15C6.37451 15.5523 6.78222 16 7.28515 16C7.78808 16 8.19579 15.5523 8.19579 15V9H13.6596C14.1625 9 14.5702 8.55229 14.5702 8C14.5702 7.44772 14.1625 7 13.6596 7H8.19579V1Z" /></svg>
						<span class="caption">Nouvelle question</span>
					</router-link>

					<select name="filter-primary_themes"
									class="vgt-select"
									v-if="primary_themes.length && !user_is_regular"
									v-model="primaryThemesFilter"
									@change="filterQuestionThemes">
						<option value="">Thème principal</option>
						<option :value="theme.value"
										v-for="theme in primary_themes"
										:key="theme.value">{{ theme.text }}</option>
					</select>

					<select name="filter-secondary_themes"
									class="vgt-select"
									v-if="secondary_themes.length && !user_is_regular"
									v-model="secondaryThemesFilter"
									@change="filterQuestionThemes">
						<option value="">Thème secondaire</option>
						<option :value="theme.value"
										v-for="theme in secondary_themes"
										:key="theme.value">{{ theme.text }}</option>
						>
					</select>


					<div class="read-questions-toggle flex-row flex-center-v"
							 v-if="!user_is_regular">
						<input class="custom-checkbox"
									 type="checkbox"
									 id="readFilter"
									 v-model="questionsUnreadFilter"
									 @change="filterQuestionsRead" />
						<label for="readFilter">Questions non-lues</label>
					</div>


					<div class="has-chat-messages-filter flex-row flex-center-v"
							 v-if="!user_is_regular">
						<input type="checkbox"
									 id="hasChatMessagesFilter"
									 name="hasChatMessagesFilter"
									 v-model="questionsHasMessagesFilter"
									 @change="filterQuestionsWithChat" />
						<label for="hasChatMessagesFilter">Avec échanges</label>
					</div>


					<button class="button--link button-clear-filters"
									@click="clearFilters"
									title="Supprimer les filtres">
						<svg role="img"
								 aria-hidden="true"
								 class="icon"
								 xmlns="http://www.w3.org/2000/svg"
								 width="14"
								 height="14"
								 fill="none"
								 viewBox="0 0 14 14"><path fill="currentColor" fill-rule="evenodd" d="M13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893Z" clip-rule="evenodd"/><path fill="currentColor" fill-rule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z" clip-rule="evenodd" /></svg>
						<span class="caption"
									v-if="onMobile">Supprimer les filtres</span>
					</button>


					<div class="input-group toggle--help-button"
							 v-if="user_is_regular">
						<label for="toggleHelp"
									 class="toggle-help"
									 :class="helpIsVisible ? 'is-visible' : ''">Charte d’utilisation
							<input type="checkbox"
										 id="toggleHelp"
										 v-model="helpIsVisible"
										 @change="updateToggleStatus" />
						</label>
					</div>

					<div class="card--help flex-item--100"
							 v-if="user_is_regular">
						<details :open="helpIsVisible">
							<summary hidden>Charte d’utilisation</summary>
							<ul role="list"
									class="list--help">
								<li>
									<p class="item--title"><strong>Notre site est exclusivement réservé à la vaccinologie :</strong></p>
									<em>les vaccins, la vaccination, les stratégies vaccinales, les contrindications, l’actualité vaccinale, …</em>
								</li>
								<li>
									<p class="item--title"><strong>Une seule question posée à la fois.</strong></p>
									<em>Plusieurs questions justifient autant d’envois que de questions.</em>
								</li>
								<li>
									<p class="item--title"><strong>Plus la demande est complète en renseignements fournis (dates, âges, …),</strong></p>
									<em>plus la réponse proposée pourra rendre service.</em>
								</li>
								<li>
									<p class="item--title"><strong>Des sites de référence en vaccinologie sont proposés avec chaque réponse d’expert.</strong></p>
									<em>Ils permettent de résoudre bien des situations.</em>
								</li>

								<li>
									<p class="item--title"><strong>La plateforme est ouverte aux questions :</strong></p>
									<em>tous les jours, week-ends et jours fériés compris, pour une réponse dans les 48 heures. Le signal « urgent » ne devra être utilisé qu'à bon escient !</em>
								</li>
							</ul>
						</details>
					</div>
				</div>

				<div class="card shadow empty-state flex-col flex-center flex-item--100"
						 v-show="!rows.length">
					<span v-if="!user_is_coordinator && !user_is_expert && !user_is_expert_ext">Ajoutez une nouvelle question !</span>
					<span v-else>Aucune question pour le moment</span>
				</div>

				<div class="card shadow question-table-wrapper flex-item--100"
						 v-show="rows.length">
					<vue-good-table v-if="questions"
													ref="questionsTable"
													mode="remote"
													:totalRows="totalRecords"
													:isLoading="isLoading"
													:columns="columns"
													:rows="rows"
													:paginate="true"
													:pagination-options="paginationOptions"
													:globalSearch="false"
													:search-options="{
														enabled: false,
														skipDiacritics: true,
														placeholder: 'Rechercher dans cette liste',
													}"
													:select-options="selectedRowsOptions"
													:sort-options="{
														enabled: true,
														initialSortBy: { field: 'date_created', type: 'desc' },
													}"
													@on-selected-rows-change="selectionChanged"
													@on-page-change="onPageChange"
													@on-sort-change="onSortChange"
													@on-column-filter="onColumnFilter"
													@on-per-page-change="onPerPageChange"
													styleClass="vgt-table striped">
						{/* @on-row-mouseenter="onRowMouseEnter" @on-row-click="onSelectRowClick" @on-cell-click="onSelectRowClick" */}

						<div slot="selected-row-actions">
							<button class="button--link"
											type="button"
											@click.prevent="completeQuestionsConfirm">Clore les questions</button>
						</div>

						<template slot="table-column"
											slot-scope="props">
							<span v-if="props.column.field == 'actions'"></span>
							<span v-else>{{ props.column.label }}</span>
						</template>

						<template slot="table-row"
											slot-scope="props">
							<!-- Question title: link to question -->
							<div class="question-title-wrap flex-row flex-center-v"
									 v-if="props.column.field == 'title'">

								<router-link class="flex-item--grow"
														 :class="props.row.is_read ? 'is-read' : ''"
														 :to="`/question/${props.row.id}`">{{ props.formattedRow[props.column.field] }}</router-link>

								<span class="question-has-chat"
											v-if="!user_is_regular && parseInt(props.row.num_chat_messages)"
											:title="`${parseInt(props.row.num_chat_messages)} échange(s) entre experts`">
									<svg class="icon"
											 role="img"
											 viewBox="0 0 15 15"
											 fill="none"
											 xmlns="http://www.w3.org/2000/svg"
											 width="15"
											 height="15"><path d="M3.5 11.493H4v-.5h-.5v.5zm0 2.998H3a.5.5 0 00.8.4l-.3-.4zm4-2.998v-.5h-.167l-.133.1.3.4zm-3-7.496H4v1h.5v-1zm6 1h.5v-1h-.5v1zm-6 1.998H4v1h.5v-1zm4 1H9v-1h-.5v1zM3 11.493v2.998h1v-2.998H3zm.8 3.398l4-2.998-.6-.8-4 2.998.6.8zm3.7-2.898h6v-1h-6v1zm6 0c.829 0 1.5-.67 1.5-1.5h-1c0 .277-.223.5-.5.5v1zm1.5-1.5V1.5h-1v8.994h1zM15 1.5c0-.83-.671-1.5-1.5-1.5v1c.277 0 .5.223.5.5h1zM13.5 0h-12v1h12V0zm-12 0C.671 0 0 .67 0 1.5h1c0-.277.223-.5.5-.5V0zM0 1.5v8.993h1V1.5H0zm0 8.993c0 .83.671 1.5 1.5 1.5v-1a.499.499 0 01-.5-.5H0zm1.5 1.5h2v-1h-2v1zm3-6.996h6v-1h-6v1zm0 2.998h4v-1h-4v1z" fill="currentColor"></path></svg>
								</span>

								<span class="question-has-refs"
											v-if="questionHasReferences(props.row)"
											:title="`${props.row.num_references} référence(s)`">
									<svg class="icon"
											 role="img"
											 viewBox="0 0 15 15"
											 fill="none"
											 xmlns="http://www.w3.org/2000/svg"
											 width="15"
											 height="15"><path d="M13.5 7.5l-5.757 5.757a4.243 4.243 0 01-6-6l5.929-5.929a2.828 2.828 0 014 4l-5.758 5.758a1.414 1.414 0 01-2-2L9.5 3.5" fill="none" stroke="currentColor"></path></svg>
								</span>

								<details v-if="!onMobile"
												 class="question-excerpt"
												 :ref="`excerpt${props.row.id}`"
												 :data-excerpt="props.row.id"
												 @click="excerptShow"
												 @mouseenter="excerptShow"
												 @mouseleave="excerptHide">
									<summary class="details-title">
										<svg class="icon"
												 role="img"
												 viewBox="0 0 15 15"
												 fill="none"
												 xmlns="http://www.w3.org/2000/svg"
												 width="15"
												 height="15"><path d="M.5 7.5l-.464-.186a.5.5 0 000 .372L.5 7.5zm14 0l.464.186a.5.5 0 000-.372L14.5 7.5zm-7 4.5c-2.314 0-3.939-1.152-5.003-2.334a9.368 9.368 0 01-1.449-2.164 5.065 5.065 0 01-.08-.18l-.004-.007v-.001L.5 7.5l-.464.186v.002l.003.004a2.107 2.107 0 00.026.063l.078.173a10.368 10.368 0 001.61 2.406C2.94 11.652 4.814 13 7.5 13v-1zm-7-4.5l.464.186.004-.008a2.62 2.62 0 01.08-.18 9.368 9.368 0 011.449-2.164C3.56 4.152 5.186 3 7.5 3V2C4.814 2 2.939 3.348 1.753 4.666a10.367 10.367 0 00-1.61 2.406 6.05 6.05 0 00-.104.236l-.002.004v.001H.035L.5 7.5zm7-4.5c2.314 0 3.939 1.152 5.003 2.334a9.37 9.37 0 011.449 2.164 4.705 4.705 0 01.08.18l.004.007v.001L14.5 7.5l.464-.186v-.002l-.003-.004a.656.656 0 00-.026-.063 9.094 9.094 0 00-.39-.773 10.365 10.365 0 00-1.298-1.806C12.06 3.348 10.186 2 7.5 2v1zm7 4.5a68.887 68.887 0 01-.464-.186l-.003.008-.015.035-.066.145a9.37 9.37 0 01-1.449 2.164C11.44 10.848 9.814 12 7.5 12v1c2.686 0 4.561-1.348 5.747-2.665a10.366 10.366 0 001.61-2.407 6.164 6.164 0 00.104-.236l.002-.004v-.001h.001L14.5 7.5zM7.5 9A1.5 1.5 0 016 7.5H5A2.5 2.5 0 007.5 10V9zM9 7.5A1.5 1.5 0 017.5 9v1A2.5 2.5 0 0010 7.5H9zM7.5 6A1.5 1.5 0 019 7.5h1A2.5 2.5 0 007.5 5v1zm0-1A2.5 2.5 0 005 7.5h1A1.5 1.5 0 017.5 6V5z" fill="currentColor"></path></svg>
									</summary>
									<div class="details-body">
										<p class="flex-row flex-center-v">
											<svg role="img"
													 viewBox="0 0 15 15"
													 fill="none"
													 xmlns="http://www.w3.org/2000/svg"
													 width="15"
													 height="15"
													 class="icon"><path d="M3.5 11.493H4v-.5h-.5v.5zm0 2.998H3a.5.5 0 00.8.4l-.3-.4zm4-2.998v-.5h-.167l-.133.1.3.4zm-3-7.496H4v1h.5v-1zm6 1h.5v-1h-.5v1zm-6 1.998H4v1h.5v-1zm4 1H9v-1h-.5v1zM3 11.493v2.998h1v-2.998H3zm.8 3.398l4-2.998-.6-.8-4 2.998.6.8zm3.7-2.898h6v-1h-6v1zm6 0c.829 0 1.5-.67 1.5-1.5h-1c0 .277-.223.5-.5.5v1zm1.5-1.5V1.5h-1v8.994h1zM15 1.5c0-.83-.671-1.5-1.5-1.5v1c.277 0 .5.223.5.5h1zM13.5 0h-12v1h12V0zm-12 0C.671 0 0 .67 0 1.5h1c0-.277.223-.5.5-.5V0zM0 1.5v8.993h1V1.5H0zm0 8.993c0 .83.671 1.5 1.5 1.5v-1a.499.499 0 01-.5-.5H0zm1.5 1.5h2v-1h-2v1zm3-6.996h6v-1h-6v1zm0 2.998h4v-1h-4v1z" fill="currentColor"></path></svg>
											<strong>{{ props.row.title }}</strong>
										</p>
										<p v-html="props.row.excerpt"></p>
									</div>
								</details>
							</div>

							<!-- Question urgent -->
							<span v-else-if="props.column.field == 'is_urgent'">
								<!-- <div v-if="user_is_coordinator">
                  <button @click="toggleUrgentQuestion(props.row.id)" type="button" class="button--icon button--outline-invert" title="basculer l'état">
                    <span class="icon" v-if="!!parseInt(props.row.is_urgent)">❗</span>
                    <span class="icon" v-else>❕</span>
                  </button>
                </div> -->
								<div>
									<span class="icon"
												v-if="!!parseInt(props.row.is_urgent)">❗</span>
									<span class="icon"
												v-else>❕</span>
								</div>
							</span>

							<!-- Question starred -->
							<span class="toggle-starred"
										:class="!!parseInt(props.row.is_starred) ? 'is-starred' : ''"
										v-else-if="props.column.field == 'is_starred' && !user_is_regular">
								<button @click="toggleStarredQuestion(props.row.id)"
												type="button"
												class="button--icon button--outline-invert"
												title="basculer l'état">
									<svg class="icon icon--star"
											 role="img"
											 viewBox="0 0 15 15"
											 fill="none"
											 xmlns="http://www.w3.org/2000/svg"
											 width="15"
											 height="15"><path fill="none" d="M7.5 12.04l-4.326 2.275L4 9.497.5 6.086l4.837-.703L7.5 1l2.163 4.383 4.837.703L11 9.497l.826 4.818L7.5 12.041z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path></svg>
								</button>
							</span>


							<!-- Question starred (user favorites) -->
							<span class="toggle-starred"
										:class="!!parseInt(props.row.is_user_favorite) ? 'is-user-favorite' : ''"
										v-else-if="props.column.field == 'is_user_favorite' && !user_is_regular">
								<button @click="toggleUserStarredQuestion(props.row.id)"
												type="button"
												class="button--icon button--outline-invert"
												title="basculer l'état">
									<svg class="icon icon--star"
											 role="img"
											 viewBox="0 0 15 15"
											 fill="none"
											 xmlns="http://www.w3.org/2000/svg"
											 width="15"
											 height="15"><path fill="none" d="M7.5 12.04l-4.326 2.275L4 9.497.5 6.086l4.837-.703L7.5 1l2.163 4.383 4.837.703L11 9.497l.826 4.818L7.5 12.041z" stroke="#3aaa35" stroke-linecap="round" stroke-linejoin="round"></path></svg>
								</button>
							</span>

							<!-- Question Status: value/caption -->
							<router-link v-else-if="props.column.field == 'status'"
													 class="flex-item--grow"
													 :class="`tag--${props.formattedRow[props.column.field].status}`"
													 :to="`/question/${props.row.id}`">
								{{ props.formattedRow[props.column.field].caption }}
							</router-link>

							<!-- Question assignment: not assigned -->
							<span v-else-if="props.column.field == 'expert' && !props.row.expert"
										:class="`tag--ongoing`">
								Non affectée
							</span>

							<!-- Question assignment: assigned, update assignment button -->
							<button class="button--icon"
											v-else-if="props.column.field == 'actions' && props.row.expert"
											:disabled="props.row.status === 'complete'"
											@click="assignUserModal(props.row)">
								<svg role="img"
										 class="icon"
										 xmlns="http://www.w3.org/2000/svg"
										 width="20"
										 height="16"
										 fill="none"
										 viewBox="0 0 20 16">
																																																																																																																																																																																																																																																																																																																																																																																																																																																				                  <path
																																																																																																																																																																																																																																																																																																																																																																																																																																																				                    fill="currentColor"
																																																																																																																																																																																																																																																																																																																																																																																																																																																				                    fill-rule="evenodd"
																																																																																																																																																																																																																																																																																																																																																																																																																																																				                    d="M1.17157 10.7716C1.92172 10.0214 2.93913 9.6 4 9.6H9.6C10.6609 9.6 11.6783 10.0214 12.4284 10.7716 13.1786 11.5217 13.6 12.5391 13.6 13.6V15.2C13.6 15.6418 13.2418 16 12.8 16 12.3582 16 12 15.6418 12 15.2V13.6C12 12.9635 11.7471 12.353 11.2971 11.9029 10.847 11.4529 10.2365 11.2 9.6 11.2H4C3.36348 11.2 2.75303 11.4529 2.30294 11.9029 1.85286 12.353 1.6 12.9635 1.6 13.6V15.2C1.6 15.6418 1.24183 16 .8 16 .358172 16 0 15.6418 0 15.2V13.6C0 12.5391.421428 11.5217 1.17157 10.7716zM6.8 1.6C5.47452 1.6 4.4 2.67452 4.4 4 4.4 5.32548 5.47452 6.4 6.8 6.4 8.12548 6.4 9.2 5.32548 9.2 4 9.2 2.67452 8.12548 1.6 6.8 1.6zM2.8 4C2.8 1.79086 4.59086 0 6.8 0 9.00914 0 10.8 1.79086 10.8 4 10.8 6.20914 9.00914 8 6.8 8 4.59086 8 2.8 6.20914 2.8 4zM12.8 7.2C12.8 6.75817 13.1582 6.4 13.6 6.4H18.4C18.8418 6.4 19.2 6.75817 19.2 7.2 19.2 7.64183 18.8418 8 18.4 8H13.6C13.1582 8 12.8 7.64183 12.8 7.2z"
																																																																																																																																																																																																																																																																																																																																																																																																																																																				                    clip-rule="evenodd"
																																																																																																																																																																																																																																																																																																																																																																																																																																																				                  />
																																																																																																																																																																																																																																																																																																																																																																																																																																																				                </svg>
							</button>

							<!-- Question assignment: unassigned, update assignment button -->
							<button class="button--icon-primary"
											v-else-if="props.column.field == 'actions' && !props.row.expert"
											:disabled="props.row.status === 'complete'"
											@click="assignUserModal(props.row)">
								<svg class="icon"
										 role="img"
										 xmlns="http://www.w3.org/2000/svg"
										 width="20"
										 height="16"
										 fill="none"
										 viewBox="0 0 20 16">
																																																																																																																																																																																																																																																																																																																																																																																																																																																				                  <path
																																																																																																																																																																																																																																																																																																																																																																																																																																																				                    fill="currentColor"
																																																																																																																																																																																																																																																																																																																																																																																																																																																				                    fill-rule="evenodd"
																																																																																																																																																																																																																																																																																																																																																																																																																																																				                    d="M1.17157 10.7716C1.92172 10.0214 2.93913 9.6 4 9.6H9.6C10.6609 9.6 11.6783 10.0214 12.4284 10.7716 13.1786 11.5217 13.6 12.5391 13.6 13.6V15.2C13.6 15.6418 13.2418 16 12.8 16 12.3582 16 12 15.6418 12 15.2V13.6C12 12.9635 11.7471 12.353 11.2971 11.9029 10.847 11.4529 10.2365 11.2 9.6 11.2H4C3.36348 11.2 2.75303 11.4529 2.30294 11.9029 1.85286 12.353 1.6 12.9635 1.6 13.6V15.2C1.6 15.6418 1.24183 16 .8 16 .358172 16 0 15.6418 0 15.2V13.6C0 12.5391.421428 11.5217 1.17157 10.7716zM6.8 1.6C5.47452 1.6 4.4 2.67452 4.4 4 4.4 5.32548 5.47452 6.4 6.8 6.4 8.12548 6.4 9.2 5.32548 9.2 4 9.2 2.67452 8.12548 1.6 6.8 1.6zM2.8 4C2.8 1.79086 4.59086 0 6.8 0 9.00914 0 10.8 1.79086 10.8 4 10.8 6.20914 9.00914 8 6.8 8 4.59086 8 2.8 6.20914 2.8 4zM16 4C16.4418 4 16.8 4.35817 16.8 4.8V9.6C16.8 10.0418 16.4418 10.4 16 10.4 15.5582 10.4 15.2 10.0418 15.2 9.6V4.8C15.2 4.35817 15.5582 4 16 4z"
																																																																																																																																																																																																																																																																																																																																																																																																																																																				                    clip-rule="evenodd"
																																																																																																																																																																																																																																																																																																																																																																																																																																																				                  />
																																																																																																																																																																																																																																																																																																																																																																																																																																																				                  <path
																																																																																																																																																																																																																																																																																																																																																																																																																																																				                    fill="currentColor"
																																																																																																																																																																																																																																																																																																																																																																																																																																																				                    fill-rule="evenodd"
																																																																																																																																																																																																																																																																																																																																																																																																																																																				                    d="M12.8 7.2C12.8 6.75817 13.1582 6.4 13.6 6.4H18.4C18.8418 6.4 19.2 6.75817 19.2 7.2C19.2 7.64183 18.8418 8 18.4 8H13.6C13.1582 8 12.8 7.64183 12.8 7.2Z"
																																																																																																																																																																																																																																																																																																																																																																																																																																																				                    clip-rule="evenodd"
																																																																																																																																																																																																																																																																																																																																																																																																																																																				                  />
																																																																																																																																																																																																																																																																																																																																																																																																																																																				                </svg>
							</button>

							<!-- Any other column (number, date, …) -->
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>

						<div slot="emptystate">
							<p class="text-center">
								Aucune question ne correspond aux filtres sélectionnés.
							</p>
						</div>

						<!-- <div slot="loadingContent">
              Chargement…
            </div> -->
					</vue-good-table>
				</div>
			</section>
		</div>

		<assign-expert-modal :open="modalAssignUserVisible"
												 :users="experts"
												 :question-id="this.modalAssignQuestionID"
												 @modalClose="modalClose"
												 @click="assignUser" />

		<dashboard-options-modal :open="modalDashboardOptionsVisible"
														 :isOpen="modalDashboardOptionsVisible"
														 @questionsLoadOptions="refreshQuestions"
														 @modalClose="modalClose"></dashboard-options-modal>

	</article>
</template>


<script>
import { appConfig } from "../config.js";
import { HTTP } from "../http-common.js";
import isMobile from "ismobilejs";
import { EventBus } from "../event-bus.js";
import requestAnimFrame from "@/libs/request-timeout-interval/requestAnimFrame.js"; // clearRequestTimeout, …
import requestTimeout from "@/libs/request-timeout-interval/requestTimeout.js";
import requestInterval from "@/libs/request-timeout-interval/requestInterval.js";


// Vue Good Table
// import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

// Assign Expert Modal
import AssignExpertModal from "../components/AssignExpertModal.vue";

// Dashboard options modal
import DashboardOptionsModal from "../components/DashboardOptionsModal.vue";

// Dashboard component
export default {
	name: "dashboard",

	components: {
		VueGoodTable,
		AssignExpertModal,
		DashboardOptionsModal
	},

	data: function () {
		return {
			selectedRowsOptions: {
				enabled: false,
				selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
				// selectionInfoClass: 'custom-class',
				selectionText: "question sélectionnée",
				clearSelectionText: "Annuler",
				disableSelectInfo: false, // disable the select info panel on top
			},
			selectedQuestions: {},
			helpIsVisible: this.$store.state.helpDisplayStatus,
			questions: [],
			experts: [],
			themes: [],
			questionsUnreadFilter: false,
			questionsHasMessagesFilter: false,
			primaryThemesFilter: "",
			secondaryThemesFilter: "",
			modalDashboardOptionsVisible: false,
			modalAssignUserVisible: false,
			modalAssignQuestionID: 0,
			statusFilterDropdownItems: [
				// NOTE: ATTENTION ESPACES INSÉCABLES !
				// {value: "draft", text: "Brouillon"},
				{ value: "pending", text: "En attente de réponse" },
				{ value: "ongoing", text: "Réponse disponible" },
				{ value: "complete", text: "Terminé" },
			],
			// Table server mode
			totalRecords: 0,
			serverParams: {
				options: this.$store.state.questionsLoadOptions,
				columnFilters: {},
				sort: [
					{
						field: "",
						type: "",
					},
				],
				page: 1,
				perPage: 20,
			},
			// table pagination
			paginationOptions: {
				enabled: true,
				nextLabel: "suivant",
				prevLabel: "précédent",
				rowsPerPageLabel: "lignes par page",
				ofLabel: "sur",
				perPage: 20,
				pageLabel: "page", // for 'pages' mode
				allLabel: "Toutes",
			},

			columns: [
				// {
				// 	label: "№",
				// 	field: "id",
				// 	type: "number",
				// 	sortable: true,
				// 	formatFn: this.zeroPadding,
				// 	tdClass: "question-number"
				// },

				{
					label: "Titre de la question",
					field: "title",
					sortable: true,
					tdClass: "question-title",
				},

				{
					label: "Date",
					field: "date_created",
					type: "date",
					dateInputFormat: "yyyy-MM-dd HH:mm:ss",
					dateOutputFormat: "dd/MM/yyyy HH:mm",
					sortable: true,
					thClass: "vgt-left-align",
					tdClass: "vgt-left-align question-date",
				},

				{
					label: "Lue",
					field: "is_read",
					formatFn: this.formatIsRead,
					hidden: true,
					sortable: true,
					thClass: "vgt-left-align",
					tdClass: "vgt-left-align question-is_read",
				},

				{
					label: "Avec échange",
					field: "num_chat_messages",
					hidden: true,
					sortable: true,
					thClass: "vgt-left-align",
					tdClass: "vgt-left-align question-is_read",
				},


			],
			rows: [],

			search: "",
			isLoading: false,
			isTableSetup: false,
		};
	},

	computed: {

		// Display questions load options
		questionsLoadOptions() {
			return this.$store.state.questionsLoadOptions;
		},

		// Current user data
		user: function () {
			return this.$store.state.userData;
		},

		// Is a regular user?
		user_is_regular: function () {
			let r = this.user.roles.reduce((acc, val, i, roles) => acc + parseInt(val));
			return r < 2;
		},

		// Is the user an admin? (role 4)
		user_is_admin: function () {
			return this.user.roles.indexOf(4) !== -1;
		},

		// Is the user a coordinator? (role 3)
		user_is_coordinator: function () {
			return this.user.roles.indexOf(3) !== -1;
		},

		// Is the user an expert? (role 2)
		user_is_expert: function () {
			return this.user.roles.indexOf(2) !== -1;
		},

		// Is the user an expert extended? (role 5)
		user_is_expert_ext: function () {
			return this.user.roles.indexOf(5) !== -1;
		},

		/**
		 * Current table filters
		 *
		 * @return object
		 */
		currentFilters() {
			return this.serverParams.columnFilters;
		},

		/**
		 * Toggle questions checkboxes
		 *
		 * @return boolean
		 */
		checkboxesEnabled() {
			if ((this.currentFilters.status !== "complete" && this.currentFilters.status !== "" && !this.user_is_regular && parseInt(this.currentFilters.expert) === 0) || this.user_is_coordinator) {
				return true;
			}
			return false;
		},

		onMobile() {
			return isMobile(window.navigator).any;
		},

		primary_themes: function () {
			if (!this.themes) return [];
			let themes = this.themes.filter((el) => el.type === "primary");
			themes = themes.map((t) => ({ value: t.id, text: t.name }));
			return themes;
		},

		secondary_themes: function () {
			if (!this.themes) return [];
			let themes = this.themes.filter((el) => el.type === "secondary");
			themes = themes.map((t) => ({ value: t.id, text: t.name }));
			return themes;
		},
	},

	methods: {

		refreshQuestions() {
			this.updateParams(this.serverParams);
			this.getQuestions();
		},

		formatIsUserFavorite(value) {
			return !!value;
		},

		formatIsRead(value) {
			return !!value;
		},

		/**
		 * Complete selected questions
		 *
		 * @return void
		 */
		completeQuestionsConfirm(event) {
			event.stopImmediatePropagation();
			event.stopPropagation();
			let msg = '<div class="message-wrapper"><strong>Clore ces questions&nbsp;?</strong> <br>Les médecins demandeurs ne pourront plus y répondre.</div>';

			this.$toasted.show(msg, {
				containerClass: "toasted",
				theme: "primary",
				position: "center",
				duration: null,
				closeOnSwipe: false,
				action: [
					{
						text: "Annuler",
						onClick: (e, toastObject) => {
							toastObject.goAway(0);
							return;
						},
					},
					{
						text: "Confirmer",
						onClick: (e, toastObject) => {
							toastObject.goAway(0);
							return this.completeQuestions();
						},
					},
				],
			});
		},

		/**
		 * Complete selected questions
		 *
		 * @return void
		 */
		completeQuestions() {
			const qids = this.selectedQuestions.map((q) => parseInt(q.id));

			this.$store
				.dispatch("COMPLETE_QUESTIONS", qids)
				.then((payload) => {
					if (payload.processed && payload.processed === true) {
						this.$toasted.global.appSuccess({
							message: "Les questions sélectionnées sont maintenant closes.",
						});
					} else {
						this.$toasted.global.appSuccess({
							message: `<strong>${payload.processed} questions closes</strong>.<br>Vous pouvez clore uniquement les questions qui vous sont affectées.`,
						});
					}
					this.getQuestions();
				})
				.catch((error) => {
					console.error("COMPLETE_QUESTIONS", error);
				});
		},

		async clearFilters() {
			this.$refs.questionsTable.reset();

			this.questionsUnreadFilter = false;
			this.questionsHasMessagesFilter = false;
			this.primaryThemesFilter = "";
			this.secondaryThemesFilter = "";
			this.search = '';

			this.serverParams.columnFilters = {};
			this.serverParams.themesFilters = {};
			this.serverParams.questionsUnreadFilter = this.questionsUnreadFilter;
			this.serverParams.questionsHasMessagesFilter = this.questionsHasMessagesFilter;

			// update store
			await this.$store.dispatch("STORE_DASHBOARD_FILTERS", {});
			await this.$store.dispatch("STORE_DASHBOARD_SEARCH", '');

			// update questions checkboxes
			this.selectedRowsOptions.enabled = this.checkboxesEnabled;

			// kick reactivity with new server params
			this.updateParams(this.serverParams);
			// refresh
			this.getQuestions();
		},

		// Update fetch questions params
		updateParams(newProps) {
			newProps.options = this.$store.state.questionsLoadOptions; // reload from store
			this.serverParams = Object.assign({}, this.serverParams, newProps);
		},

		// onRowMouseEnter(params) {
		//   console.log(params);
		// },

		/**
		 * Handle clicks on checkboxes/checkboxes cells
		 *
		 * @param {object} params
		 * @return void
		 */
		// onSelectRowClick(params) {
		//   // check if user click on a checkbox
		//   console.log(params.event.target.matches(".vgt-checkbox-col > [type=checkbox]"));

		//   if (!params.event.target.matches(".vgt-checkbox-col > [type=checkbox]")) {
		//     console.log("prevent!");
		//     params.event.preventDefault();
		//     params.event.stopImmediatePropagation();
		//     params.event.stopPropagation();
		//     return;
		//   }
		//   let row = params.row;

		//   // allow only assigned questions
		//   let rowIndex = this.rows.findIndex((r) => parseInt(r.id) === parseInt(row.id));
		//   let expert_rows = this.rows.filter((q) => parseInt(q.expert_id) === parseInt(this.user.id));
		//   let expert_rows_id = expert_rows.map((r) => parseInt(r.id));

		//   if (rowIndex === -1) {
		//     params.event.preventDefault();
		//     return;
		//   }

		//   if (expert_rows_id.includes(parseInt(row.id))) {
		//     this.$set(this.rows[rowIndex], "vgtSelected", true);
		//   } else {
		//     event.preventDefault();
		//     this.$set(this.rows[rowIndex], "vgtSelected", false);
		//   }
		// },

		selectionChanged(params) {
			let rows = params.selectedRows;
			this.selectedQuestions = rows;

			if (this.selectedQuestions.length > 1) {
				this.selectedRowsOptions.selectionText = "questions sélectionnées";
			} else {
				this.selectedRowsOptions.selectionText = "question sélectionnée";
			}
		},

		onSortChange(sorts) {
			this.updateParams({
				sort: sorts,
			});

			this.getQuestions();
		},

		// Save params
		// params.currentPage - current page that pagination is at
		// params.prevPage - previous page
		// params.currentPerPage - number of items per page
		// params.total - total number of items in the table
		onPageChange(params) {
			// save pagination preferences
			this.$store.state.currentPaginationOptions.savedCurrentPage = params.currentPage;

			// update params and reload questions
			this.updateParams({ page: params.currentPage });

			this.getQuestions();
		},

		onPerPageChange(params) {
			this.$store.state.currentPaginationOptions.perPage = params.currentPerPage;

			// update params and reload questions
			this.updateParams({ perPage: params.currentPerPage });

			this.getQuestions();
		},

		// Fetch experts
		async getExperts() {
			let users = await this.$store.dispatch("GET_EXPERT_USERS");
			// Remove Coordinators
			this.experts = users.filter(
				(u) =>
					// Expert (ext) + Coordinator
					(u.roles.includes("3") && u.roles.includes("5")) ||
					// OR Expert only
					(!u.roles.includes("3") && u.roles.includes("5"))
			);
		},

		questionHasReferences(q) {
			return parseInt(q.num_references) > 0;
		},

		toggleExcerpts(e) {
			let excerpt = e.target.closest("details");
			let table = this.$refs.questionsTable.$el;
			let details = table.querySelectorAll("[data-excerpt]");

			details.forEach((el) => {
				if (el.getAttribute("data-excerpt") !== excerpt.getAttribute("data-excerpt")) el.removeAttribute("open");
			});
		},

		excerptHide(e) {
			e.preventDefault();
			let details = e.target.closest("details");
			details.removeAttribute("open");
		},

		excerptShow(e) {
			e.preventDefault();
			let details = e.target.closest("details");
			details.setAttribute("open", true);
		},

		updateToggleStatus(e) {
			this.$store.dispatch("HELP_DISPLAY_STATUS", this.helpIsVisible);
		},

		/**
		 * Search event debounce
		 *
		 * @param {Event} e
		 * @return void
		 */
		handleAutoSearch(e) {
			// Delay the event execution (ex. while typing)
			if (window.timeoutClock) window.clearRequestTimeout(window.timeoutClock);
			window.timeoutClock = window.requestTimeout(
				function () {
					this.searchQuestions(this.search);
				}.bind(this),
				400
			);
		},

		/**
		 * Handle search question event
		 *
		 * @param {Event} e
		 * @return void
		 */
		handleQuestionSearch(e) {
			this.searchQuestions(this.search);
		},

		/**
		 * Get questions with search query
		 *
		 * @param {string} search
		 * @return void
		 */
		searchQuestions(search) {
			// Save search
			this.$store.dispatch("STORE_DASHBOARD_SEARCH", search);
			this.getQuestions();
		},


		// Filter questions with chat messages
		filterQuestionsWithChat() {
			// Add a new property "questionsHasMessagesFilter"
			// as "columnFilters" is overwritten by vue-good-table.
			this.updateParams({ questionsHasMessagesFilter: this.questionsHasMessagesFilter });

			// Save filters
			let previousFilters = this.$store.state.dashboardFilters || {};
			previousFilters.questionsHasMessagesFilter = this.questionsHasMessagesFilter;

			this.$store.dispatch("STORE_DASHBOARD_FILTERS", previousFilters);

			this.getQuestions();
		},

		// Filter question read
		filterQuestionsRead() {
			// Add a new property "questionsUnreadFilter"
			// as "columnFilters" is overwritten by vue-good-table.
			this.updateParams({ questionsUnreadFilter: this.questionsUnreadFilter });

			// Save filters
			let previousFilters = this.$store.state.dashboardFilters || {};
			previousFilters.questionsUnreadFilter = this.questionsUnreadFilter;

			this.$store.dispatch("STORE_DASHBOARD_FILTERS", previousFilters);

			this.getQuestions();
		},

		/**
		 * Filter questions by themes
		 *
		 * @return void
		 */
		filterQuestionThemes() {
			if (!this.isTableSetup) return;
			// let previousFilters = (await this.$store.state.dashboardFilters) || {};

			let themesFilters = {};

			// Primary theme
			if (this.primaryThemesFilter) {
				themesFilters.primary_theme = this.primaryThemesFilter;
			}

			// Secondary theme
			if (this.secondaryThemesFilter) {
				themesFilters.secondary_theme = this.secondaryThemesFilter;
			}

			// Add a new property "themesFilters"
			// as "columnFilters" is overwritten by vue-good-table.
			this.updateParams({ themesFilters: themesFilters });

			// Save themes filters
			let previousFilters = this.$store.state.dashboardFilters || {};
			previousFilters.themesFilters = themesFilters;

			this.$store.dispatch("STORE_DASHBOARD_FILTERS", previousFilters);

			this.getQuestions();
		},

		// Fetch themes
		async getThemes() {
			return await this.$store.dispatch("GET_THEMES");
		},

		/**
	 * Toogle urgent question status
	 */
		toggleUrgentQuestion: function (questionID) {
			if (!questionID) return false;

			// get the question in question :P
			let qIndex = this.questions.findIndex((q) => q.id === questionID);
			if (qIndex === -1) return false;

			// get the current starred status
			let urgentStatus = parseInt(this.questions[qIndex].is_urgent);

			// inverse the status
			if (isNaN(urgentStatus) || !urgentStatus) urgentStatus = "1";
			else urgentStatus = "0";

			// Update the question
			this.questions[qIndex].is_urgent = urgentStatus;

			// Save the question status
			this.$store
				.dispatch("PUT_QUESTION_URGENT_STATUS", { questionID, urgentStatus })
				.then((success) => {
					if (!success) {
						// EventBus.$emit("trackEvent", "statut urgent", `Erreur statut urgent ${this.questionID} pour userID ${this.user.id}`);
						this.$toasted.global.appError({
							message: "<strong>Impossible de modifier le statut&nbsp;!",
						});
					}
				})
				.catch((error) => {
					// EventBus.$emit("trackEvent", "statut urgent", `Erreur statut urgent ${this.questionID} pour userID ${this.user.id}`);
					console.error("PUT_QUESTION_URGENT_STATUS", error);
				});
		},

		/**
		 * Toggle starred question status
		 */
		toggleStarredQuestion: function (questionID) {
			if (!questionID) return false;

			// get the question in question :P
			let qIndex = this.questions.findIndex((q) => q.id === questionID);
			if (qIndex === -1) return false;

			// get the current starred status
			let starredStatus = parseInt(this.questions[qIndex].is_starred);

			// inverse the status
			if (isNaN(starredStatus) || !starredStatus) starredStatus = "1";
			else starredStatus = "0";

			// Update the question
			this.questions[qIndex].is_starred = starredStatus;

			// Save the question status
			const type = ''; // user favorites only
			this.$store
				.dispatch("PUT_QUESTION_STARRED_STATUS", { questionID, starredStatus, type })
				.then((success) => {
					if (!success) {
						// EventBus.$emit("trackEvent", "statut favori", `Erreur statut favori ${this.questionID} pour userID ${this.user.id}`);
						this.$toasted.global.appError({
							message: "<strong>Impossible de modifier le statut&nbsp;!",
						});
					}
				})
				.catch((error) => {
					// EventBus.$emit("trackEvent", "statut favori", `Erreur statut favori ${this.questionID} pour userID ${this.user.id}`);
					console.error("PUT_QUESTION_STARRED_STATUS", error);
				});
		},

		/**
		 * Toggle user starred question status
		 */
		toggleUserStarredQuestion: function (questionID) {
			if (!questionID) return false;

			// get the question in question :P
			let qIndex = this.questions.findIndex((q) => q.id === questionID);
			if (qIndex === -1) return false;

			// get the current starred status
			let starredStatus = parseInt(this.questions[qIndex].is_user_favorite);

			// inverse the status
			if (isNaN(starredStatus) || !starredStatus) starredStatus = "1";
			else starredStatus = "0";

			// Update the question
			this.questions[qIndex].is_user_favorite = starredStatus;

			// Save the question status
			const type = 'user';
			this.$store
				.dispatch("PUT_QUESTION_STARRED_STATUS", { questionID, starredStatus, type })
				.then((success) => {
					if (!success) {
						// EventBus.$emit("trackEvent", "statut favori", `Erreur statut favori ${this.questionID} pour userID ${this.user.id}`);
						this.$toasted.global.appError({
							message: "<strong>Impossible de modifier le statut&nbsp;!",
						});
					}
				})
				.catch((error) => {
					// EventBus.$emit("trackEvent", "statut favori", `Erreur statut favori ${this.questionID} pour userID ${this.user.id}`);
					console.error("PUT_QUESTION_STARRED_STATUS", error);
				});
		},

		clearToasts: function () {
			this.$toasted.clear();
		},

		openDashboardOptions() {
			this.modalDashboardOptionsVisible = true;
		},

		modalClose: function (event) {
			this.modalDashboardOptionsVisible = false;
			this.modalAssignUserVisible = false;
			this.modalAssignQuestionID = 0;
		},

		assignUser: function (user) {
			let payload = { questionID: this.modalAssignQuestionID, userID: user.id };

			this.$store
				.dispatch("POST_ASSIGN_EXPERT", payload)
				.then((res) => {
					// Toast
					let expert = this.experts.find((e) => e.id === user.id);
					let expertName = expert ? `${expert.firstname} ${expert.lastname}` : "";

					this.$toasted.global.appSuccess({
						message: `La question a été affectée à <strong>${expertName}</strong>.`,
					});

					// Update the questions (the table will react accordingly)

					this.getQuestions();
					this.modalClose();

					// Analytics
					// EventBus.$emit("trackEvent", "Affectation", `Utilisateur ${user.id} affecté à questionID ${this.modalAssignQuestionID} par userID ${this.user.id}`);
				})
				.catch((error) => {
					console.error("getQuestions error…", error);
				});
		},

		// assign user modal
		assignUserModal: function (row) {
			// NOTE: `row` = table row properties: id (question ID), (int) expert_id and (object) expert if any
			this.modalAssignUserVisible = true;
			this.modalAssignQuestionID = parseInt(row.id);

			// Load experts then show modal
			this.$store
				.dispatch("GET_EXPERT_USERS", {})
				.then((experts) => {
					this.experts = experts;
					this.modalAssignUserVisible = true;
					this.modalAssignQuestionID = parseInt(row.id);
				})
				.catch((error) => {
					console.error("getQuestions error…", error);
				});
		},

		/**
		 * Filters State
		 *
		 * @param {object} params
		 * @return void
		 */
		onColumnFilter: async function (params) {
			// prevent previous filters overwrite
			if (!this.isTableSetup) return;
			let previousFilters = (await this.$store.state.dashboardFilters) || {};

			// Save current "expert" filter (assignments)
			if (typeof params.columnFilters.expert === "undefined") params.columnFilters.expert = "";
			previousFilters.expert = params.columnFilters.expert;

			// Save current "status" filter
			if (typeof params.columnFilters.status === "undefined") params.columnFilters.status = "";
			previousFilters.status = params.columnFilters.status;

			// Save current "is_starred" filter
			if (typeof params.columnFilters.is_starred === "undefined") params.columnFilters.is_starred = "";
			previousFilters.is_starred = params.columnFilters.is_starred;

			// Save current "is_user_favorite" filter
			if (typeof params.columnFilters.is_user_favorite === "undefined") params.columnFilters.is_user_favorite = "";
			previousFilters.is_user_favorite = params.columnFilters.is_user_favorite;

			// Update store
			this.$store.dispatch("STORE_DASHBOARD_FILTERS", previousFilters);

			// update params and reload questions
			this.updateParams(params);

			// update checkboxes
			this.selectedRowsOptions.enabled = this.checkboxesEnabled;

			// reload questions
			this.getQuestions();
		},

		// Table cell click
		handleCellClick: function (params) {
			let field = params.column.field;

			// Open question
			let questionID = params.row.id;
			if (!questionID) return console.error("no question id!");
			this.$router.push(`/question/${questionID}`);
		},

		// Open question
		onQuestionClick: function (params) {
			let questionID = params.row.id;
			if (!questionID) return console.error("no question id!");
			this.$router.push(`/question/${questionID}`);
		},

		// Tri sur les assignements (états, expert)
		sortAssignmentRow: function (x, y, col, rowX, rowY) {
			// Order by expert name
			if (!!rowX.expert && !!rowY.expert) {
				if (rowX.expert.lastname < rowY.expert.lastname) return -1;
				if (rowX.expert.lastname > rowY.expert.lastname) return 1;
				return 0;
			}

			// Order by expert / no expert
			return rowX.expert_id ? -1 : 1;
		},

		// Tri sur les médecins émandeurs
		sortOwnerRow: function (x, y, col, rowX, rowY) {
			// Order by owner user name
			if (!!rowX.owner && !!rowY.owner) {
				if (rowX.owner.lastname < rowY.owner.lastname) return -1;
				if (rowX.owner.lastname > rowY.owner.lastname) return 1;
				return 0;
			}
		},

		// Retourne le nom de l'expert assigné / propriétaire de la question
		formatUserRow: function (value) {
			return `${value.firstname} ${value.lastname}`;
		},

		// Retourne le libellé du statut
		formatQuestionStatus: function (value) {
			let statuses = this.statusFilterDropdownItems;
			let s = statuses.find((item) => {
				return item.value === value;
			});
			if (!s) return { status: "", caption: "?" };
			return { status: value, caption: s.text };
		},

		// Zero padding
		zeroPadding: function (value) {
			return `${value}`.padStart(2, "0");
		},

		// Move search and filter elements to toolbar…
		setupToolbar: function () {
			// Move elements around…
			let toolbar = document.querySelector('[data-toolbar="questions"]');
			let search = document.querySelector(".vgt-global-search");
			let filters = document.querySelectorAll(".filter-th .vgt-select");

			if (search) toolbar.prepend(search);

			filters.forEach((el) => {
				let filterName = el.getAttribute("name");
				let filter = toolbar.querySelector(`[name=${filterName}]`);
				if (!filter) {
					toolbar.appendChild(el);
				}
			});
		},

		// setupTable
		setupTable: async function () {
			var userID = this.user.id;

			let col_status = {
				label: "Statut",
				field: "status",
				sortable: true,
				formatFn: this.formatQuestionStatus,
				tdClass: "question-status",
				filterOptions: {
					enabled: true,
					placeholder: "Tout statut", // placeholder for filter input
					filterDropdownItems: [
						// NOTE: ATTENTION ESPACES INSÉCABLES !
						// {value: "draft", text: "Brouillon"},
						{ value: "pending", text: "En attente de réponse" },
						{ value: "ongoing", text: "Réponse disponible" },
						{ value: "complete", text: "Terminé" },
					],
				},
			};
			// First column
			this.columns.splice(1, 0, col_status);

			// Coordinator: display assignments and question owner
			if (this.user_is_coordinator || this.user_is_expert_ext) {

				// Starred question
				let starred = {
					label: "Favoris",
					title: "Favoris",
					field: "is_starred",
					html: true,
					sortable: true,
					thClass: "vgt-left-align is-reference-question question-alt-status",
					tdClass: "vgt-left-align is-reference-question question-alt-status",
					filterOptions: {
						enabled: true,
						placeholder: "Toute question",
						filterDropdownItems: [{ value: "1", text: "Favoris communs" }, { value: "2", text: "Favoris perso" }],
					},
				};
				// Add the row in the second position (after title)
				this.columns.splice(1, 0, starred);

				// Question owner
				this.columns.push({
					label: "Médecin",
					field: "owner",
					formatFn: this.formatUserRow,
					sortable: true,
					sortFn: this.sortOwnerRow,
					tdClass: "owner-user",
				});

				// Assigned expert column
				// Assignement
				let assignmentCol = {
					label: "Affectations",
					field: "expert",
					formatFn: this.formatUserRow,
					sortable: true,
					sortFn: this.sortAssignmentRow,
					tdClass: "assignment-status",
					filterOptions: {
						enabled: true,
						placeholder: "Toute affectation",
						filterDropdownItems: [
							{ value: -1, text: "Affectées" },
							{ value: 0, text: "Affectées à moi" },
							{ value: -2, text: "Non affectées" },
							// … Expert IDs …
						],
						filterValue: "",
					},
				};

				this.columns.push(assignmentCol);

				// Action buttons (last col on desktop, both sides on mobile)
				// Coordinators only
				if (this.user_is_coordinator) {
					let actionsColumn = {
						label: "Action",
						field: "actions",
						thClass: "th-actions",
						tdClass: "td-actions",
						sortable: false,
					};

					if (isMobile(window.navigator).any) this.columns = [actionsColumn, ...this.columns];
					else this.columns.push(actionsColumn);
				}
			}

			// User starred questions
			let user_favorite = {
				label: "Favoris perso",
				title: "Favoris perso",
				field: "is_user_favorite",
				html: true,
				sortable: true,
				thClass: "vgt-left-align is-user-favorite question-alt-status",
				tdClass: "vgt-left-align is-user-favorite question-alt-status",
				filterOptions: {
					enabled: true,
					placeholder: "Toute question",
					filterDropdownItems: [{ value: "1", text: "Favoris perso" }],
				},
			};
			this.columns.splice(2, 0, user_favorite);

			// Urgent
			let urgent = {
				label: "Urgent",
				title: "Urgent",
				field: "is_urgent",
				html: true,
				sortable: true,
				thClass: "vgt-left-align is-urgent question-alt-status",
				tdClass: "vgt-left-align is-urgent question-alt-status",
			};

			// Add the row after title or after starred
			let urgentRowPosition = this.user_is_regular ? 1 : 3;
			this.columns.splice(urgentRowPosition, 0, urgent);

			// Setup filters
			await this.setupTableFilters();

			// Initial questions loading
			this.getQuestions();

			this.isTableSetup = true;
		},


		/**
		 * Debounce questions loading
		 * (Give time to set filters, sort, etc.)
		 *
		 * @param {string} search 
		 * @return void
		 */
		getQuestions: function (search) {
			if (window.getQuestionsDebounce) window.clearRequestTimeout(window.getQuestionsDebounce);
			window.getQuestionsDebounce = window.requestTimeout(() => {
				this.fetchQuestions();
			}, 666);
		},


		/**
		 * Récupération des questions
		 *
		 * @return void
		 */
		fetchQuestions: async function () {
			this.isLoading = true;

			// Send pagination options… (serverParams)
			const search = !this.search ? null : this.search;
			let params = this.serverParams;

			await this.$store
				.dispatch("FETCH_QUESTIONS", { search, params })
				.then((payload) => {
					if (!payload.questions) questions = [];
					this.questions = payload.questions;
					this.totalRecords = payload.totalRecords ? payload.totalRecords : this.questions.length;

					// Fill up the table
					this.rows = this.questions;

					// Checkboxes: Allow only assigned questions to experts
					// if (this.user_is_expert || this.user_is_expert_ext) {
					//   let expert_rows = this.rows.filter((q) => parseInt(q.expert_id) === parseInt(this.user.id));
					//   let expert_rows_id = expert_rows.map((r) => parseInt(r.id));

					//   this.rows.map((r) => {
					//     if (expert_rows_id.includes(parseInt(r.id))) {
					//       r.vgtDisabled = false;
					//     } else {
					//       r.vgtDisabled = true;
					//     }
					//     return r;
					//   });
					// }

					// Correct pagination when questions are filtered
					if (this.totalRecords < this.serverParams.perPage && this.serverParams.page > 1) {
						this.paginationOptions.setCurrentPage = 1;
					}
				})
				.catch((error) => {
					console.error("getQuestions error…", error);
				});

			this.isLoading = false;
		},

		// Setup table filters upon user role
		setupTableFilters: async function () {

			// Previous search?
			this.search = await this.$store.state.dashboardSearch ?? '';

			// Reduce assignments filter options for experts
			if (this.user_is_expert || this.user_is_expert_ext || this.user_is_coordinator) {
				let column = this.columns.find((col) => col.field === "expert");
				let items = [...column.filterOptions.filterDropdownItems].filter((el) => !["Affectées", "Non affectées"].includes(el));

				// Append the list of experts
				this.$store.dispatch("GET_EXPERT_USERS").then((users) => {
					// Remove Coordinators and current expert user
					let experts = users.filter((u) => {
						// Experts (ext) + Coordinators
						return (
							(u.roles.includes("3") && u.roles.includes("5")) ||
							// OR Expert only
							(!u.roles.includes("3") &&
								u.roles.includes("5") &&
								// Not current user
								u.id != this.user.id)
						);
					});

					let expertNames = experts.map((el) => {
						return { value: el.id, text: `${el.lastname} ${el.firstname}` };
					});

					items = [...items, ...expertNames];
					this.$set(column.filterOptions, "filterDropdownItems", items);
				});
			}

			// Filters State
			// Load previous filter value if any
			let previousFilters = await this.$store.state.dashboardFilters;

			// NOTE: "empty" filters must also be saved (Toute validation, tout status, toute affectation, …)
			let hasFilters = !previousFilters || (typeof previousFilters === "string" && previousFilters === "") ? false : true;

			if (hasFilters) {
				// Question assignment filter ("expert" field)
				let column_expert = this.columns.find((col) => col.field === "expert");
				if (column_expert) this.$set(column_expert.filterOptions, "filterValue", previousFilters.expert);

				// Question status filter
				let column_status = this.columns.find((col) => col.field === "status");
				if (column_status) this.$set(column_status.filterOptions, "filterValue", previousFilters.status);

				// Question is_starred filter
				let column_is_starred = this.columns.find((col) => col.field === "is_starred");
				if (column_is_starred) this.$set(column_is_starred.filterOptions, "filterValue", previousFilters.is_starred);

				// Question is_user_favorite filter
				let column_is_user_favorite = this.columns.find((col) => col.field === "is_user_favorite");
				if (column_is_user_favorite) this.$set(column_is_user_favorite.filterOptions, "filterValue", previousFilters.is_user_favorite);

				// Question has experts chat messages
				// let column_has_chat_messages = this.columns.find((col) => col.field === "num_chat_messages");
				// if (column_has_chat_messages) this.$set(column_has_chat_messages.filterOptions, "filterValue", previousFilters?.num_chat_messages);

				// Themes filters
				// Selected option
				if (previousFilters.themesFilters && previousFilters.themesFilters.primary_theme) this.primaryThemesFilter = previousFilters.themesFilters.primary_theme;
				if (previousFilters.themesFilters && previousFilters.themesFilters.secondary_theme) this.secondaryThemesFilter = previousFilters.themesFilters.secondary_theme;

				// Question read status filter
				this.questionsUnreadFilter = previousFilters.questionsUnreadFilter;
				this.questionsHasMessagesFilter = previousFilters.questionsHasMessagesFilter;

				//  serverParams
				let params = {
					columnFilters: {
						expert: previousFilters.expert,
						status: previousFilters.status,
						is_starred: previousFilters.is_starred,
						is_user_favorite: previousFilters.is_user_favorite,
						has_chat_messages: previousFilters.has_chat_messages,
					},
					questionsUnreadFilter: this.questionsUnreadFilter,
					questionsHasMessagesFilter: this.questionsHasMessagesFilter,
				};


				if (this.primaryThemesFilter || this.secondaryThemesFilter) {
					params.themesFilters = {};

					if (this.primaryThemesFilter) {
						params.themesFilters.primary_theme = this.primaryThemesFilter
					}

					if (this.secondaryThemesFilter) {
						params.themesFilters.secondary_theme = this.secondaryThemesFilter
					}
				}

				this.updateParams(params);
			}

			// No filter saved, set default filter for experts:
			else {
				if (this.user_is_expert_ext || this.user_is_expert) {
					let column = this.columns.find((col) => col.field === "expert");
					// Question assignment filter ("expert" field)
					let column_expert = this.columns.find((col) => col.field === "expert");
					if (column_expert) {
						this.$set(column.filterOptions, "filterValue", "0"); // Affectées à moi
						//  serverParams
						let params = {
							columnFilters: {
								expert: 0,
							},
						};

						this.updateParams(params);
					}
				}
			}
		},

	},

	async mounted() {
		// Fetch experts
		if (!this.user_is_regular) this.getExperts();

		// Load pagination preferences
		this.paginationOptions = { ...this.paginationOptions, ...this.$store.state.currentPaginationOptions };

		// Display last page
		if (this.paginationOptions.savedCurrentPage) {
			this.paginationOptions.setCurrentPage = this.paginationOptions.savedCurrentPage;
			delete this.paginationOptions.savedCurrentPage;
		}

		// Display all questions to regular users
		if (this.user_is_regular) {
			await this.$store.dispatch("UPDATE_QUESTIONS_LOAD_OPTIONS", {
				"limit": "all",
				"includeComplete": true
			});
			this.updateParams(this.serverParams);
		}

		// Load themes
		this.themes = await this.getThemes();

		// Setup the table columns, rows, filters, …
		// then load questions.
		this.setupTable();
	},

	updated() {
		// Setup toolbar
		this.setupToolbar();
	},
};
</script>


<style lang="scss">
@import "@/scss/dashboard.scss";
</style>

<style lang="scss" scoped>
::v-deep {
	.question-table-wrapper .vgt-wrap {
		margin-top: -2rem;
	}

	[name="vgt-is_user_favorite"] {
		display: none !important
	}
}
</style>